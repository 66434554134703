<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import { provide, ref } from 'vue'

import { record } from '@/api/shopping'
export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(record)
        const columns = ref([
            // {prop: 'shopId', label: 'ID', width: 100},
            {prop: 'shopName', label: '商品名称'},
            {prop: 'userName', label: '用户名称'},
            {prop: 'shopImg', label: '图片', type: 'image'},
        ])
        const searchFormItems = ref([
            {label: '商品名称', prop: 'shopName', type: 'input'},
        ])
        let editorForm = ref({})
        let table = ref(null)
        provide('editorForm', editorForm)

        const methods = {
        }

        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>